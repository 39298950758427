/* fonts */
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-light-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-regular-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-medium-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-semibold-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-bold-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-lightitalic-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-regularitalic-webfont.woff2")
      format("woff2"),
    url("../assets/Gilroy/gilroy-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-mediumitalic-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-mediumitalic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-semibolditalic-webfont.woff2")
      format("woff2"),
    url("../assets/Gilroy/gilroy-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../assets/Gilroy/gilroy-bolditalic-webfont.woff2") format("woff2"),
    url("../assets/Gilroy/gilroy-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
